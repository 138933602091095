'use client';
import { useState, useRef } from 'react';
import { useServerInsertedHTML } from '@mentimeter/next-navigation';
import {
  createRenderer as felaCreateRenderer,
  renderToNodeList,
  RendererProvider,
  prefixer,
  fallback,
} from '@mentimeter/ragnar-react';
import * as Fonts from '@mentimeter/ragnar-fonts';
import { reset, setup } from '@mentimeter/ragnar-reset';

export function FelaProvider({ children }: { children: React.ReactNode }) {
  const hasInsertedHTML = useRef(false);
  const [renderer] = useState(() => createRenderer());

  useServerInsertedHTML(() => {
    if (!hasInsertedHTML.current) {
      hasInsertedHTML.current = true;
      return renderToNodeList(renderer);
    }
    return null;
  });
  return <RendererProvider renderer={renderer}>{children}</RendererProvider>;
}

function createRenderer(): any {
  const renderer = felaCreateRenderer({
    plugins: [prefixer(), fallback()],
    selectorPrefix: 'm-',
  });

  renderer.renderFont(
    'MentiText',
    Fonts.MentiText.regular.files,
    Fonts.MentiText.regular.style,
  );
  renderer.renderFont(
    'MentiText',
    Fonts.MentiText.semiBold.files,
    Fonts.MentiText.semiBold.style,
  );
  renderer.renderFont(
    'MentiDisplay',
    Fonts.MentiDisplay.regular.files,
    Fonts.MentiDisplay.regular.style,
  );
  renderer.renderFont(
    'MentiDisplay',
    Fonts.MentiDisplay.semiBold.files,
    Fonts.MentiDisplay.semiBold.style,
  );

  renderer.renderStatic(reset(setup('#__next', false)));
  return renderer;
}
