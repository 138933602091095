import type { Orientation } from './types';

/*
 * All the information we need regarding browser
 */

// Touch and handheld
// https://github.com/airbnb/is-touch-device/blob/master/src/index.js
export function isTouchDevice(): boolean {
  // TODO: DocumentTouch has been removed from the window type in Typescript because it is deprecated.
  // There are probably better ways to detect touch capabilites now that we should switch to.
  return (
    Boolean(
      typeof window !== 'undefined' &&
        ('ontouchstart' in window ||
          ((window as any).DocumentTouch &&
            typeof document !== 'undefined' &&
            document instanceof (window as any).DocumentTouch)),
    ) || Boolean(typeof navigator !== 'undefined' && navigator.maxTouchPoints)
  );
}

// Get orientation
export const getOrientation = (): Orientation =>
  window.matchMedia('(orientation: portrait)') ? 'portrait' : 'landscape';
