export const getMediaqueriesFromBreakpoints = (
  breakpoints: number[],
): string[] => {
  // Sort asc
  const sortedBreakpoints = breakpoints.sort((a, b) => a - b);

  return sortedBreakpoints.map((value, index) => {
    if (index === 0) {
      return `screen and (max-width: ${value}px)`;
    }
    const prevValue: number = sortedBreakpoints[index - 1] ?? 0;
    if (index === sortedBreakpoints.length - 1) {
      return `screen and (min-width: ${prevValue + 1}px)`;
    }
    return `screen and (min-width: ${
      prevValue + 1
    }px) and (max-width: ${value}px)`;
  });
};
