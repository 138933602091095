import * as React from 'react';
import { getMediaqueriesFromBreakpoints } from './mediaquery';
import type { Context, Orientation } from './types';
import DeviceContext from './createContext';
import { DEFAULT_BREAKPOINT, DEFAULT_ORIENTATION } from './constants';
import { isTouchDevice, getOrientation } from './utils';

interface Props {
  children: React.ReactNode;
  breakpoints: number[];
}

// Variables
const isTouch = isTouchDevice();

export const useBreakpoint = (initialBreakpoints: number[]): number | null => {
  const breakpoints = React.useMemo(
    () => getMediaqueriesFromBreakpoints([0, ...initialBreakpoints, Infinity]),
    [initialBreakpoints],
  );
  const [breakpoint, setBreakpoint] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window.matchMedia === 'undefined'
    ) {
      return;
    }

    const update = (breakpointIndex: number) => {
      setBreakpoint(breakpointIndex);
    };

    // Calculate the initial breakpoint after first render
    const initialBreakpoint = breakpoints.findIndex(
      (query) => window.matchMedia(query).matches,
    );
    setBreakpoint(initialBreakpoint);

    // Set listener for each breakpoint
    const bpListeners: [string, (evt: MediaQueryListEvent) => void][] =
      breakpoints.map((bp, index) => [
        bp,
        ({ matches }: MediaQueryListEvent): void => {
          // If it is a match => update the reducer
          if (matches) {
            update(index);
          }
        },
      ]);

    bpListeners.forEach(([bp, listener]) =>
      window.matchMedia(bp).addListener(listener),
    );

    return () =>
      bpListeners.forEach(([bp, listener]) =>
        window.matchMedia(bp).removeListener(listener),
      );
  }, [breakpoints]);

  return breakpoint;
};

const useOrientation = (): Orientation | null => {
  const [orientation, setOrientation] = React.useState<Orientation | null>(
    null,
  );

  React.useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window.matchMedia === 'undefined'
    ) {
      return;
    }

    const updateOrientation = () => {
      setOrientation(getOrientation());
    };

    // Set orientation after first render
    updateOrientation();

    window.addEventListener('orientationchange', updateOrientation);

    return () =>
      window.removeEventListener('orientationchange', updateOrientation);
  }, []);

  return orientation;
};

const Provider = ({ children, breakpoints: initialBreakpoints }: Props) => {
  const breakpoint = useBreakpoint(initialBreakpoints);
  const orientation = useOrientation();
  const state = React.useMemo<Context>(
    () => ({
      initialized: breakpoint !== null && orientation !== null,
      orientation: orientation ?? DEFAULT_ORIENTATION,
      breakpoint: breakpoint ?? DEFAULT_BREAKPOINT,
      isTouch,
    }),
    [breakpoint, orientation],
  );

  return (
    <DeviceContext.Provider value={state}>{children}</DeviceContext.Provider>
  );
};

export default Provider;
