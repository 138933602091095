/*
 *   Menti-text (Mentimeter Brand Font)
 */
const light = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Light.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Light.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Light.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '300' },
};

const lightItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-LightItalic.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-LightItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-LightItalic.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '300', fontStyle: 'italic' },
};

const regular = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '400' },
};

const regularItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-RegularItalic.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-RegularItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-RegularItalic.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '400', fontStyle: 'italic' },
};

const semiBold = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-SemiBold.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-SemiBold.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-SemiBold.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '600' },
};

const semiBoldItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-SemiBoldItalic.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-SemiBoldItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-SemiBoldItalic.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '600', fontStyle: 'italic' },
};

const bold = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Bold.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Bold.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Bold.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '700' },
};

const boldItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-BoldItalic.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-BoldItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-BoldItalic.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '700', fontStyle: 'italic' },
};

const extraBold = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-ExtraBold.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-ExtraBold.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-ExtraBold.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '800' },
};

const extraBoldItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-ExtraBoldItalic.woff2',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-ExtraBoldItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-ExtraBoldItalic.ttf',
  ],
  style: { fontDisplay: 'swap', fontWeight: '800', fontStyle: 'italic' },
};

export const MentiText = {
  light,
  lightItalic,
  regular,
  regularItalic,
  semiBold,
  semiBoldItalic,
  bold,
  boldItalic,
  extraBold,
  extraBoldItalic,
};

export const mentiTextKeys = Object.keys(MentiText) as Array<
  keyof typeof MentiText
>;
