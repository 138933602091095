import { createVotingClient, setupHttp } from '@mentimeter/http-clients';
import { getUrlPrefixByRegion } from '@mentimeter/region';
import { isServer } from '@mentimeter/detect-server';
import Agent from 'agentkeepalive';
import { getIdentifier } from '../utils/identifier';

const SERVICE_NAME = process.env.NEXT_PUBLIC_SERVICE_NAME || '';
const deadlineTimeout = process.env
  .NEXT_PUBLIC_REQUEST_TIMEOUT_DEADLINE_MS as string;
const canonicalHost = process.env.NEXT_PUBLIC_CANONICAL_HOST as string;

const RATE_LIMITING_BYPASS = process.env.RATE_LIMITING_BYPASS || '';

const TIMEOUT_DEFAULT = 25000;

// Deadline timeout is the axios timeout (moved from superagent)
const timeout = parseInt(deadlineTimeout, 10) || TIMEOUT_DEFAULT;

const AGENT_SETTINGS = {
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
};

export function httpSetup(extraHeaders = {}) {
  setupHttp({
    serviceName: SERVICE_NAME,
    voting: {
      defaultRegion: 'eu',
      baseUrls: {
        eu:
          (isServer && process.env.EU_CORE_URL) ||
          `${getUrlPrefixByRegion('eu')}/core`,
        us:
          (isServer && process.env.US_CORE_URL) ||
          `${getUrlPrefixByRegion('us')}/core`,
      },
      createClient(baseURL) {
        if (isServer) {
          const isHttps = baseURL.startsWith('https://');
          return createVotingClient({
            baseURL,
            getIdentifier,
            extraHeaders: {
              Origin: canonicalHost,
              'Menti-Bypass-Rate-Limiting': RATE_LIMITING_BYPASS,
              ...extraHeaders,
            },
            timeout,
            httpAgent: !isHttps ? new Agent(AGENT_SETTINGS) : undefined,
            httpsAgent: isHttps
              ? new Agent.HttpsAgent(AGENT_SETTINGS)
              : undefined,
          });
        }
        return createVotingClient({
          baseURL,
          getIdentifier,
          timeout,
        });
      },
    },
  });
}
