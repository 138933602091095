import * as React from 'react';
import Cookies, { cookiesAreEnabled } from '@mentimeter/cookies';
import { CONSENT_COOKIE_NAME } from '../../constants/cookieConstants';

type ContextValueT = [
  boolean,
  (arg0: ((arg0: boolean) => boolean) | boolean) => void,
];

const CookieConsentContext = React.createContext<ContextValueT>([
  true,
  () => {},
]);
CookieConsentContext.displayName = 'CookieConsentContext';

export function CookieConsentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const state = React.useState(
    // If the browser has cookies disabled, don't show the banner
    !cookiesAreEnabled() ||
      // otherwise show it if consent has not yet been given
      Boolean(Cookies.get(CONSENT_COOKIE_NAME)),
  );

  return (
    <CookieConsentContext.Provider value={state}>
      {children}
    </CookieConsentContext.Provider>
  );
}

interface CookieConsentHookT {
  cookiesEnabled: boolean;
  acceptCookies: () => void;
}

export function useCookieConsent(): CookieConsentHookT {
  const [cookiesEnabled, setCookiesEnabled] =
    React.useContext(CookieConsentContext);

  const acceptCookies = React.useCallback(() => {
    // Defensive check. We should never expose the "accept cookies" button
    // to users that don't have cookies enabled in their browser.
    if (cookiesAreEnabled())
      Cookies.set({
        type: 'necessary',
        name: CONSENT_COOKIE_NAME,
        value: '1',
        options: {
          expires: 365,
        },
      });

    setCookiesEnabled(true);
  }, [setCookiesEnabled]);

  return { cookiesEnabled, acceptCookies };
}
